@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap);
@layer components{
  .nav-items{
    @apply hover:text-white text-lg transition-all duration-100 ease-linear font-bold;
  }
  .wallet{
    @apply text-[#ECB365] text-lg hover:text-[#bb9054] transition-all duration-100 ease-linear font-bold;
  }
}

@font-face {
  font-family: SfPro;
  src:url(/static/media/font.90da7ab1.ttf);
}
@font-face {
  font-family: HarmoniaSansRegular;
  src:url(/static/media/HarmoniaSansW01Regular.4d9e0b80.ttf);
}
@font-face {
  font-family: HarmoniaSansBold;
  src:url(/static/media/HarmoniaSansW01Bold.8ba1dcb1.ttf);
}
@font-face {
  font-family: SfPro;
  src:url(/static/media/font.90da7ab1.ttf);
}

body {
  margin: 0;
  padding: 0;
  background-image: url(/static/media/bg.6e0b61d9.png);
  background-repeat: no-repeat;
  background-color: #030E35;
  font-family: 'SfPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto',
    monospace;
}
.decoration{
  margin-right: 100px;
  width: 500px;
}
.theme{
  margin-top: 30px;
  margin-left: 150px;
  font-size: 130px;
  font-weight: 700;
  line-height: 130px;
}
.wallet{
  color: #ECB365;
  font-size: large;
  font-weight: 510;
}
.wallet:hover{
  text-decoration: none;
  color: #bb9054;
}
.nav-items{
  color: #ffffff;
  font-size: 1.35rem;
  font-weight: 510;
}
.nav-items:hover{
  color: #d0d0d0;
  border-bottom: 5px solid #ECB365;
}

.active{
  border-bottom: 5px solid #ECB365;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  margin-top: 0px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #ffffff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  width: 100%;
  max-width: 100vw;
  height: 50px;
  background-color: transparent;
}

@media only screen and ((min-width: 400px) and (max-width: 768px)) {
  .nav-items {
    display: none;
  }
}
.item-container {
  display: flex;
  align-items: center;
}
.decoration {
  margin-right: 100px;
  margin-top: 2rem;
  width: 575px;
}
img {
  margin-top: 1rem;
}
.text-container {
  color: #fff;
  font-weight: 700;
  font-size: 5.5rem;
  line-height: 6.8rem;
}

.you-text {
  color: rgb(236, 179, 101);
}

.you-text:hover {
    color: rgb(129, 103, 67);
  }

.write-petiiton {
  font-size: 1.2rem;
}
.container {
  height: 100vh;
}

Button.button-css {
  background-color: rgb(236, 179, 101);
}

Button.button-css:hover {
  background-color: rgb(129, 103, 67);
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
}
.about-us img {
  width: 100px;
}
.about-us-content {
  padding: 0px 0px 16px 0px;
  text-align: center;
}
.about-us-maincontent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
}
.content {
  /* margin-left: 50px; */
  width: 500px;
}
.content h3 {
  font-family: HarmoniaSansBold;
  text-align: left;
  font-size: 5em;
}
.about-us-maincontent img {
  width: 400px;
}
.about-us-maincontent p {
  font-family: HarmoniaSansRegular;
  font-size: 18px;
  line-height: 20px;
  color: rgb(71, 71, 71);
  text-align: left;
}

.features {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ecb365;
  padding: 20px;
  height: 80vh;
}
.feature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 1200px;
  margin-top: 20px;
}
.feature-container-under {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.feature-container {
  padding: 24px;
  margin: 20px 0px;
  margin-right: 20px;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.number {
  font-family: HarmoniaSansBold;
  color: #ecb365;
  height: 100%;
  font-size: 50px;
  margin-right: 30px;
}
.features p {
  font-family: HarmoniaSansRegular;
  font-size: 18px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  text-align: left;
}

