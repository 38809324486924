.nav-bar {
  display: flex;
  justify-content: space-between;
  position: sticky;
  margin-top: 0px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #ffffff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  width: 100%;
  max-width: 100vw;
  height: 50px;
  background-color: transparent;
}

@media only screen and ((min-width: 400px) and (max-width: 768px)) {
  .nav-items {
    display: none;
  }
}